import { parse, ParsedQs } from 'qs'
import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export default function useParsedQueryString(): ParsedQs {
  const { search } = useLocation()
  return useMemo(
    () => {
      return search && search.length > 1 ? parse(search, { parseArrays: false, ignoreQueryPrefix: true }) : {}},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  )
}
