import { ChainId } from '@glhf-libs/sdk'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Button, LinkExternal } from '@hurricaneswap/uikit'
import { ArrowUpCircle } from 'react-feather'
import { AutoColumn } from '../Column'
import { getBscScanLink } from '../../utils'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
}

const ConfirmIcon = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M110 55.4005V60.0005C109.994 70.7827 106.503 81.2739 100.047 89.9097C93.5908 98.5454 84.5164 104.863 74.1768 107.92C63.8372 110.977 52.7863 110.61 42.6724 106.873C32.5584 103.137 23.9233 96.231 18.0548 87.1859C12.1863 78.1407 9.39896 67.4409 10.1084 56.6821C10.8178 45.9234 14.986 35.6822 21.9914 27.4859C28.9968 19.2896 38.4639 13.5775 48.9809 11.2013C59.498 8.82509 70.5013 9.91223 80.35 14.3005" stroke="#05A660" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M110 20L60 70.05L45 55.05" stroke="#05A660" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

)

const TransactionSubmittedContent = ({ onDismiss, chainId, hash }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
        <ConfirmedIcon>
          <ConfirmIcon/>
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <LinkExternal href={getBscScanLink(chainId, hash, 'transaction')}>View on Explorer</LinkExternal>
          )}
          {/* <Button onClick={onDismiss} mt="20px">
            Close
          </Button> */}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
