import React from 'react'
import { Price } from '@glhf-libs/sdk'
import { SyncAltIcon, Text } from '@hurricaneswap/uikit'
import { StyledBalanceMaxMini } from './styleds'
import { useActiveWeb3React } from '../../hooks'
import { useBaseCoin } from '../../hooks/useBaseCoin'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)
  const {chainId} = useActiveWeb3React()
  const quoteSymbol = useBaseCoin(price?.quoteCurrency, chainId)
  const baseSymbol = useBaseCoin(price?.baseCurrency, chainId)
  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${quoteSymbol} per ${baseSymbol}`
    : `${baseSymbol} per ${quoteSymbol}`

  return (
    <Text fontSize="14px" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            <SyncAltIcon width="20px" color="primary" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
