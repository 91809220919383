import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@glhf-libs/v2-periphery/build/IUniswapV2Router02.json'
// import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@glhf-libs/sdk'
import { AVAX_ROUTER_ADDRESS, baseChainID, BSC_ROUTER_ADDRESS, HECO_ROUTER_ADDRESS, ROUTER_ADDRESS } from '../constants'
import { TokenAddressMap } from '../state/lists/hooks'
import StationV2ABI from '../constants/abis/avaxStationV2.json'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    // console.log('getAddress (value)', getAddress(value))
    return getAddress(value)
  } catch {
    return false
  }
}
const BSCSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  43114: '',
  43113: 'testnet.',
  128: '',
  256: 'testnet.',
  1: '.',
  3: 'testnet.',
  4: 'testnet.',
  5: 'testnet.',
  42: 'testnet.',
  56: '',
  97: 'testnet.'
}

export function getBscScanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  // @ts-ignore
  const prefix = (chainId === 43113) ? 'https://testnet.snowtrace.io/' : (chainId === 43114) ? 'https://snowtrace.io/' : `https://${BSCSCAN_PREFIXES[chainId] || BSCSCAN_PREFIXES[ChainId.MAINNET]}bscscan.com`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(Math.floor(num)), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  // console.log(slippage,'slippage')
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  const _ROUTER_ADDRESS = (_ === ChainId.AVAX_TESTNET || _ === ChainId.AVAX_MAINNET) ? AVAX_ROUTER_ADDRESS : (_ === ChainId.HECO_TESTNET || _ === ChainId.HECO_MAINNET) ? HECO_ROUTER_ADDRESS : (_ === ChainId.BSC_TESTNET || _ === ChainId.BSC_MAINNET) ? BSC_ROUTER_ADDRESS : ROUTER_ADDRESS
  return getContract(_ROUTER_ADDRESS, StationV2ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export const CHAIN_LIST = [
  {
    chainId: `0xa869`,// A 0x-prefixed hexadecimal chainId
    chainName: 'Avalanche FUJI C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax-test.network']
  },
  {
    chainId: `0xa86a`,// A 0x-prefixed hexadecimal chainId
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://cchain.explorer.avax.network/']
  },
  {
    chainId: `0x80`,// A 0x-prefixed hexadecimal chainId
    chainName: 'HECO',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18
    },
    rpcUrls: ['https://http-mainnet.hecochain.com'],
  },
  {
    chainId: `0x100`,// A 0x-prefixed hexadecimal chainId
    chainName: 'HECO TEST',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18
    },
    rpcUrls: ['https://http-testnet.hecochain.com'],
  },
  {
    chainId: `0x38`,// A 0x-prefixed hexadecimal chainId
    chainName: 'Binance Smart Chain Mainnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed1.ninicoin.io', 'https://bsc-dataseed2.ninicoin.io'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  {
    chainId: `0x61`,// A 0x-prefixed hexadecimal chainId
    chainName: 'Binance Smart Chain Mainnet TEST',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
    blockExplorerUrls: ['https://bscscan.com/']
  }
]


export const setupNetwork = async (_chainId = baseChainID) => {
  const provider = (window).ethereum
  if (provider) {
    const chainId = typeof (_chainId) === "number" ? _chainId || baseChainID : baseChainID
    const currentRPC = CHAIN_LIST.filter(i => i.chainId === `0x${chainId.toString(16)}`)
    // console.log('currentRPC', currentRPC)

    try {
      // @ts-ignore
      const res = await provider.request({
        method: 'wallet_addEthereumChain',
        params: [
          ...currentRPC
        ]
      })
      return true
    } catch (error) {
      return false
    }
  } else {
    console.error('Can\'t setup the network on metamask or others, because window.ethereum is undefined')
    return false
  }
}

