import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AreaChart, ResponsiveContainer, CartesianGrid, Tooltip, Area, XAxis, YAxis } from 'recharts'
import { Text, CloseIcon, Button } from '@hurricaneswap/uikit'
import moment from 'moment'
import { useSpring, useTransition, animated, config } from 'react-spring'
import { TYPE } from 'components/Shared'
import axios from 'axios'
import { useAsyncByAwait } from '../../hooks/useAyncByAwait'


const { italic: Italic } = TYPE


const getData = {
  getByHour: () => (
    axios.get (`https://api.coingecko.com/api/v3/coins/ethereum/contract/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/market_chart/range?vs_currency=usd&from=${ moment (new Date ()).subtract (1, 'days').format ('X') }&to=${ moment (new Date ()).format ('X') }`)
  ),
  getByWeek: () => (
    axios.get (`https://api.coingecko.com/api/v3/coins/ethereum/contract/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/market_chart/range?vs_currency=usd&from=${ moment (new Date ()).subtract (7, 'days').format ('X') }&to=${ moment (new Date ()).format ('X') }`)
  ),
  getByMonth: () => (
    axios.get (`https://api.coingecko.com/api/v3/coins/ethereum/contract/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2/market_chart/range?vs_currency=usd&from=${ moment (new Date ()).subtract (1, 'months').format ('X') }&to=${ moment (new Date ()).format ('X') }`)
  ),
  getByDayByCoinmarketMap: () => (
    axios.get('https://api.coinmarketcap.com/data-api/v3/cryptocurrency/detail/chart?id=1027&range=1D')
  ),
  getByWeekByCoinmarketMap: () => (
    axios.get('https://api.coinmarketcap.com/data-api/v3/cryptocurrency/detail/chart?id=1027&range=7D')
  ),
  getByMonthByCoinmarketMap: () => (
    axios.get('https://api.coinmarketcap.com/data-api/v3/cryptocurrency/detail/chart?id=1027&range=1M')
  )
}




export default function Test ({ match: { params: { currencyIdA, currencyIdB } }, history }: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const [chart, setChart] = useState ([])
  const [yDomain, setYDomain] = useState ([0, 100])
  const [type, setType]: any = useState ('getByDayByCoinmarketMap')
  const [chartResult, setChartResult]: any = useState (null)

  const filterData = (_)=>{
    const {points} :any = _
    const finalArr :any = []

    Object.keys(points).forEach(i=>{
      finalArr.push([+i * 1000, ...points[i].v])
    })

    return finalArr
  }

  // 15分钟一条记录，按星期和月请求时，后台筛选一小时一条
  const handleData = (_) => {
    const __ = filterData(_)
    const chartData: any = []
    const prices: any = []
    // @ts-ignore
    const unitLength = parseInt (__.length / 100)
    for (let i = 0; i < __.length; i++) {
      if (i % unitLength === 0 ) {
        prices.push (__[i][1])
        chartData.push ({
          time: moment (__[i][0]).format ('HH:mm A'),
          price: __[i][1].toFixed(3),
          volume: __[i][2].toFixed(3)
        })
      }
    }
    const min = Math.floor (0.99 * Math.min.apply (null, prices))
    const max = Math.ceil (1.01 * Math.max.apply (null, prices))

    setYDomain ([min, max])
    setChart (chartData)
  }


  const { load, result }: any = useAsyncByAwait ((_) => _ ())

  const fetchPrevData = (_) => sessionStorage.getItem (_)

  useEffect (() => {
    if (type && load) {
      // @ts-ignore
      const _past = JSON.parse (fetchPrevData (type))
      if (!_past || +new Date - _past.date > 900000) {
        load (getData[type])
      } else {
        setChartResult (_past)
      }
    }
  }, [load, type])


  useEffect (() => {
    if (result) {
      setChartResult (result)
      sessionStorage.setItem (type, JSON.stringify ({ ...result, date: +new Date () }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])


  useEffect (() => {
    if (chartResult && chartResult?.data?.data) {
      handleData (chartResult?.data?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartResult])

  useEffect(()=>{
    return ()=>{
      sessionStorage.clear()
    }
  }, [])

  const [fill, setFill] = useState ('transparent')
  const [stroke, setStroke] = useState ('#8884d8')
  const [payload, setPayload] = useState (0)

  const handleMouseMove = (e) => {
    if (e && e.activePayload && e.activePayload[0]) {
      setPayload (e.activePayload[0].value)
    }
  }

  return (
    <div style={ { width: '70vw', height: '50vh' } }>
      <h1 style={{fontSize: '3rem'}}>
        {payload}
      </h1>
      {
        chart && (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              onMouseMove={ handleMouseMove }
              width={ 500 }
              height={ 400 }
              data={ chart }
              margin={ {
                top: 10,
                right: 30,
                left: 0,
                bottom: 0
              } }
            >
              <XAxis dataKey="time" hide/>
              <YAxis domain={ yDomain } hide/>
              <Tooltip position={{ y: 0 }} isAnimationActive={false} contentStyle={{backgroundColor: 'transparent', border: 'none'}}/>
              <Area type="monotone" dataKey="price" stroke={ stroke } fill={ fill } fillOpacity={ 0.2 }/>
            </AreaChart>
          </ResponsiveContainer>
        )
      }


      <Button onClick={ () => {
        setType ('getByDayByCoinmarketMap')
      } }>24Hour</Button>
      <Button onClick={ () => {
        setType ('getByWeekByCoinmarketMap')
      } }>1Week</Button>
      <Button onClick={ () => {
        setType ('getByMonthByCoinmarketMap')
      } }>1Month</Button>
      <Button onClick={ () => {
        setFill ('red')
      } }>FILL COLOR</Button>
      <Button onClick={ () => {
        setStroke ('red')
      } }>STROKE COLOR</Button>

    </div>

  )
}
