import { createAction } from '@reduxjs/toolkit'

export const updateAllowPolling = createAction<{ status: boolean }>('station/updateAllowPolling')
export const updateAllowSwap = createAction<{ status: boolean }>('station/updateAllowSwap')
export const updateAllowLiquidity = createAction<{ status: boolean }>('station/updateAllowLiquidity')
export const updateAllowStation = createAction<{ status: boolean }>('station/updateAllowStation')
export const updateStationChainId = createAction<{ id: number }>('station/updateStationChainId')
export const updateLastUpdateTime = createAction<{ t: number }>('station/updateLastUpdateTime')
export const updateSync = createAction<{ status: boolean }>('station/updateSync')
export const updateError = createAction<{ status: any }>('station/updateError')

