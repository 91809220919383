import React from 'react'
import { useWeb3React } from '@web3-react/core'
// import { Button, ButtonProps, useWalletModal} from '@glhf-libs/uikit'
import { Button, ButtonProps, useWalletModal} from '@hurricaneswap/uikit'
import { ConnectorId } from '@pantherswap-libs/uikit'
import { injected, walletconnect } from 'connectors'
import useI18n from 'hooks/useI18n'
import useAuth from '../../hooks/useAuth'
import useTheme from '../../hooks/useTheme'

const UnlockButton: React.FC<ButtonProps> = props => {
  const TranslateString = useI18n()
  const { login, logout } = useAuth()
  const { account, activate, deactivate } = useWeb3React()

  const handleLogin = (connectorId: ConnectorId) => {
    if (connectorId === 'walletconnect') {
      return activate(walletconnect)
    }
    return activate(injected)
  }

  const { onPresentConnectModal } = useWalletModal(login as any, logout, account as string)

  return (
    <Button onClick={onPresentConnectModal} {...props}  style={{ width: '100%' }}>
      {TranslateString(292, 'Connect Wallet')}
    </Button>
  )
}

export default UnlockButton
