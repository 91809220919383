import React from 'react'
import { Trade, TradeType } from '@glhf-libs/sdk'
import { Card, CardBody, Text } from '@hurricaneswap/uikit'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'
import { useActiveWeb3React } from '../../hooks'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import useTheme from '../../hooks/useTheme'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { chainId } = useActiveWeb3React()
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  const inputSymbol = useBaseCoin(trade.inputAmount.currency, chainId)
  const outputSymbol = useBaseCoin(trade.outputAmount.currency, chainId)
  const {theme} = useTheme()

  return (
    <Card>
      <CardBody p='24px 24px 48px'>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">{isExactIn ? 'Minimum received' : 'Maximum sold'}</Text>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px">
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${outputSymbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${inputSymbol}` ??
                  '-'}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">Price Impact</Text>
            <QuestionHelper text="The difference between the market price and estimated price due to trade size." />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <Text fontSize="14px">Fees</Text>
            <QuestionHelper text={<Text color={theme.colors.textSubtle}>
              For each non-cross-chain assets trade, a 0.3% fee is paid <br/>
              -0.18% to LPs<br/>
              -0.1% to repurchase HCT and AVAX<br/>
            </Text>} />
          </RowFixed>
          <Text fontSize="14px">
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${inputSymbol}` : '-'}
          </Text>
        </RowBetween>
      </CardBody>
    </Card>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <div style={{zIndex:1}}>
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              <SectionBreak style={{ margin: '0 8px', maxWidth: '100%', width: 'auto', marginTop: '16px' }} />
              <AutoColumn style={{ padding: '0 8px' }}>
                <RowFixed>
                  <Text fontSize="14px">Route</Text>
                  <QuestionHelper text="Routing through these tokens resulted in the best price for your trade." />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
    </div>
  )
}
