import {createSlice} from '@reduxjs/toolkit'
import {currencyId} from '../../utils/currencyId'

export const counterSlice = createSlice({
  name: 'trading',
  initialState: {
    inputCurrency: '',
    outputCurrency: ''
  },
  reducers: {
    updateInputCurrency: (state, action) => {
        // @ts-ignore
        state.inputCurrency = action.payload
    },
    updateOutputCurrency: (state, action) => {
        // @ts-ignore
        state.outputCurrency = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {updateInputCurrency, updateOutputCurrency} = counterSlice.actions

export default counterSlice.reducer
