import { ChainId, ETHER } from '@glhf-libs/sdk'
import { useMemo } from 'react'
import { AVAX_ROUTER_ADDRESS, BSC_ROUTER_ADDRESS, HECO_ROUTER_ADDRESS, ROUTER_ADDRESS } from '../constants'

export function useRouterAddress (chainId) {
  return useMemo (() => {
    return (
      (chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET))
      ? BSC_ROUTER_ADDRESS
      : ((chainId === ChainId.AVAX_MAINNET || chainId === ChainId.AVAX_TESTNET))
        ? AVAX_ROUTER_ADDRESS
        : ((chainId === ChainId.HECO_MAINNET || chainId === ChainId.HECO_TESTNET))
          ? HECO_ROUTER_ADDRESS
          : ROUTER_ADDRESS
  }, [chainId])
}

export default useRouterAddress
