import React, { useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Area, AreaChart, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import axios from 'axios'
import moment from 'moment'
import { Button, Text, Box, Skeleton, Flex } from '@hurricaneswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import { useAsyncByAwait } from '../../hooks/useAyncByAwait'
import useParsedQueryString from '../../hooks/useParsedQueryString'


const TextAlignRight = styled.div`
  text-align: right;
  display: flex;
  justify-content: flex-end;
`

const MarginRight = styled.div`
  cursor: pointer;
  margin-right: 23px; 
`

const Container = styled.section`
  width: 100%;
  height: 400px;
`

const getTimeRange = (time)=>{
  let from = +moment().subtract(1, 'days')
  let to = +moment()
  switch (time.toUpperCase()) {
    case '24H':
      from = +moment().subtract(1, 'days')
      to = +moment()
      break;
    case '1W':
      from = +moment().subtract(7, 'days')
      to = +moment()
      break;
    case '1M':
      from = +moment().subtract(1, 'months')
      to = +moment()
      break;
  }

  return [from.toString().slice(0, -3), to.toString().slice(0, -3)];
}


// https://api.coingecko.com/api/v3/coins/tether/market_chart/range?vs_currency=usd&from=1620596104&to=1630596104
const getData = {
  getByDayByCoinGecko: async ({ name }) => {
    const [from, to] = getTimeRange('24H')
    const res = await Promise.all (name.filter (i => i).map (i =>
      axios.get (`https://api.coingecko.com/api/v3/coins/${i}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`)
    ))
    return res
  },
  getByWeekByCoinGecko: async ({ name }) => {
    const [from, to] = getTimeRange('1W')
    const res = await Promise.all (name.filter (i => i).map (i =>
      axios.get (`https://api.coingecko.com/api/v3/coins/${i}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`)
    ))
    return res
  },
  getByMonthByCoinGecko: async ({ name }) => {
    const [from, to] = getTimeRange('1M')
    const res = await Promise.all (name.filter (i => i).map (i =>
      axios.get (`https://api.coingecko.com/api/v3/coins/${i}/market_chart/range?vs_currency=usd&from=${from}&to=${to}`)
    ))
    return res
  }
}

const insufficientData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
]

const TradingViewWithCoinGecko = ({ defaultPrice, tokenId, token, tokenName }) => {
  const { inputCurrency: currencyIdA, outputCurrency: currencyIdB }: any = useParsedQueryString ()
  const [payload, setPayload] = useState (0)
  const [currentPrice, setCurrentPrice] = useState (0)
  const [chart, setChart] = useState ([])
  const [yDomain, setYDomain] = useState ([0, 100])
  const [type, setType]: any = useState ('getByDayByCoinGecko')
  const [chartResult, setChartResult]: any = useState (null)
  const theme = useContext (ThemeContext)
  const { chainId } = useWeb3React ()
  const chainIdRef = useRef (chainId)
  const [insufficientPair, setInsufficientPair]: any = useState (null)

  const filterData = (_) => {
    const points = _
    const finalArr: any = []
    if (_) {
      Object.keys (points).forEach (i => {
        finalArr.push ([+i * 1000, ...points[i].v])
      })
    }

    return finalArr
  }

  const combineToken = (_) => {
    // points: {1624370349: v: [1,2,3,4]}
    const pointA = _[0]?.data?.prices
    const pointB = _[1]?.data?.prices
    // if (!(pointA && pointB)) return setInsufficientPair('Insufficient Pair')
    if (!(pointA && pointB)) return (pointA || pointB)

    setInsufficientPair (null)


    const len = pointA.length || pointB.length
    const res: any = []
    for (let i = 0; i < len; i++) {
      if( pointA[i] && pointB[i] && pointA[i][1] && pointB[i][1] ){
        res[i] = [pointA[i][0], pointA[i][1] / pointB[i][1]]
      }
    }

    return res
  }

  // 数据在session缓存15分钟
  const handleData = (_) => {
    // const combinedData = combineToken (_)
    const  __= combineToken (_)
    const chartData: any = []
    const prices: any = []
    // @ts-ignore
    const unitLength = parseInt (__.length < 75 ? 1 : __.length / 75)
    for (let i = 0; i < __.length; i++) {
      if (i % unitLength === 0) {
        prices.push (__[i][1])
        chartData.push ({
          time: moment (__[i][0]).format ('HH:mm A'),
          price: __[i][1].toFixed (6)
        })
      }
    }
    // const min = Math.floor (0.99 * Math.min.apply (null, prices))
    const min = 0.99 * Math.min.apply (null, prices)
    // const max = Math.ceil (1.01 * Math.max.apply (null, prices))
    const max = 1.01 * Math.max.apply (null, prices)

    setPayload (chartData[chartData.length - 1]?.price)
    setCurrentPrice (chartData[chartData.length - 1]?.price)
    setYDomain ([min, max])
    setChart (chartData)
  }

  const { isLoading, load, result }: any = useAsyncByAwait ((_) => _ ())

  const fetchPrevData = (_) => sessionStorage.getItem (_)

  const fetchData = useCallback (() => {
    const selectedCurrencyLength = token?.filter (i => i.name).length || 0
    const canBeFetchedCurrencyLength = token?.filter (i => i.id).length || 0
    if (canBeFetchedCurrencyLength < 2 && selectedCurrencyLength === 1 && (canBeFetchedCurrencyLength < selectedCurrencyLength)) {
      setInsufficientPair (true)
    } else if (canBeFetchedCurrencyLength < 2 && selectedCurrencyLength === 2 && (canBeFetchedCurrencyLength < selectedCurrencyLength)) {
      setInsufficientPair (true)
    } else {
      setInsufficientPair (null)

      if (type && load && tokenId && !isLoading) {
        // @ts-ignore
        const _past = JSON.parse (fetchPrevData (type))
        if (!_past || +new Date - _past.date > 900000) {
          load (() => getData[type] ({ id: tokenId, name: tokenName }))
        } else {
          setChartResult (_past)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, load, tokenId, token, tokenName])

  const tokenA = useMemo (() => {
    if (token && token[0]) {
      return token[0]
    }
    return null
  }, [token])

  const tokenB = useMemo (() => {
    if (token && token[1]) {
      return token[1]
    }
    return null
  }, [token])


  useEffect (() => {
    sessionStorage.clear ()
    fetchData ()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenA, tokenB])

  useEffect (() => {
    fetchData ()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect (() => {
    if (result && result.length > 0) {
      setChartResult (result)
      sessionStorage.setItem (type, JSON.stringify ({ ...result, date: +new Date () }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  useEffect (() => {
    if (chartResult && Object.keys (chartResult).length > 0) {
      handleData (chartResult)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartResult])

  useEffect (() => {
    return () => {
      sessionStorage.clear ()
    }
  }, [])

  // useEffect (() => {
  //   if (chainId !== chainIdRef.current) {
  //     sessionStorage.clear ()
  //     load (() => getData[type] ({ id: tokenId, name: tokenName }))
  //     chainIdRef.current = chainId
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chainId])

  const typeForShow = useMemo (() => {
    switch (type) {
      case 'getByDayByCoinGecko':
        return 'Past 24 Hours'
      case 'getByWeekByCoinGecko':
        return 'Past 1 Week'
      case 'getByMonthByCoinGecko':
        return 'Past 1 Month'
      default:
        return 'Past 24 Hours'
    }
  }, [type])

  const priceImpact = useMemo (() => {
    return (payload - currentPrice).toFixed (6)
  }, [payload, currentPrice])

  const priceImpactPercentage = useMemo (() => {
    return (((payload - currentPrice) / currentPrice) * 100).toFixed (2)
  }, [payload, currentPrice])

  const priceImpactColor = useMemo (() => {
    let _color = theme.colors.text
    if (+priceImpact < 0) {
      _color = theme.colors.failure
    } else if (+priceImpact > 0) {
      _color = theme.colors.success
    }
    return _color
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceImpact])

  const handleMouseMove = (e) => {
    if (e && e.activePayload && e.activePayload[0]) {
      setPayload (e.activePayload[0].value)
    }
  }

  const tooltipContent = (props) => {
    return (<div style={ { color: theme.colors.text } }>{ props.label }</div>)
  }
 //  console.log(chart,'111111')
  return (
    <Container>
      {
        insufficientPair
          ? (<>
            <Text bold color={ theme.colors.text } fontSize="40px">{ insufficientPair }</Text>
            <div style={ { height: '420px' } }>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={ 500 }
                  // height={ 400 }
                  data={ insufficientData }
                  margin={ {
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                  } }
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={ theme.colors.primary } stopOpacity={ .5 }/>
                      <stop offset="80%" stopColor={ theme.colors.primary } stopOpacity={ 0 }/>
                    </linearGradient>
                  </defs>
                  <Area type="monotone" dataKey="uv" stroke={ theme.colors.primary } fill="url(#colorUv)"/>
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </>)
          : (chart && chart.length > 0 && !isLoading)
          ? (
            <>
              <Text bold color={ theme.colors.text } fontSize="40px">
                { payload || '-' } { (token && token[1]) ? token[1].symbol : 'USDT' }
              </Text>
              <Flex>
                <Text mr="10px"
                      color={ priceImpactColor }>{ priceImpact } { (token && token[1]) ? token[1].symbol : 'USDT' } ({ priceImpactPercentage }%)</Text><Text
                color={ theme.colors.text }>{ typeForShow }</Text>
              </Flex>
              <TextAlignRight>
                <MarginRight>
                  <Text onClick={ () => {
                    setType ('getByDayByCoinGecko')
                  } }
                  ><Text bold fontSize='20px'
                         color={ type === 'getByDayByCoinGecko' ? theme.colors.text : theme.colors.optionColor }>1D</Text>
                  </Text>
                </MarginRight>
                <MarginRight>
                  <Text onClick={ () => {
                    setType ('getByWeekByCoinGecko')
                  } }
                  ><Text bold fontSize='20px'
                         color={ type === 'getByWeekByCoinGecko' ? theme.colors.text : theme.colors.optionColor }>1W</Text>
                  </Text>
                </MarginRight>
                <MarginRight>
                  <Text onClick={ () => {
                    setType ('getByMonthByCoinGecko')
                  } }
                  ><Text bold fontSize='20px'
                         color={ type === 'getByMonthByCoinGecko' ? theme.colors.text : theme.colors.optionColor }>1M</Text>
                  </Text>
                </MarginRight>
              </TextAlignRight>
              <div style={ { height: '280px' } }>

                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    onMouseMove={ handleMouseMove }
                    width={ 500 }
                    height={ 400 }
                    data={ chart }
                    margin={ {
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0
                    } }
                  >
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#07C658" stopOpacity={ .1 }/>
                        <stop offset="80%" stopColor="#07C658" stopOpacity={ 0 }/>
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="time" hide/>
                    <YAxis domain={ yDomain } hide/>
                    <Tooltip position={ { y: 0 } } isAnimationActive={ false }
                             content={ tooltipContent }
                             contentStyle={ { backgroundColor: 'transparent', border: 'none' } }/>
                    <Area type="monotone" dataKey="price" strokeWidth="2px" stroke='#07C658' fill="url(#colorUv)"
                          fillOpacity={ 1 }/>
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </>
          )
          : (<Skeleton animation="waves" variant="rect" width="100%" height="100%"/>)
      }
    </Container>
  )
}

export default TradingViewWithCoinGecko
