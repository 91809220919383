import { useMemo } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Token, TokenAmount } from '@glhf-libs/sdk'
import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'

export function useTokenBalance(token?: Token, addresses?: any): TokenAmount | undefined {
    const contract = useTokenContract(token?.address, false)

    const totalSupply: any = useSingleCallResult(contract, 'balanceOf', addresses)?.result?.[0]

    return token && totalSupply ? new TokenAmount(token, totalSupply.toString()) : undefined
}

export default useTokenBalance