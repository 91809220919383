import { createReducer } from '@reduxjs/toolkit'
import {
  updateAllowPolling,
  updateAllowLiquidity,
  updateAllowSwap,
  updateStationChainId,
  updateAllowStation,
  updateLastUpdateTime,
  updateSync,
  updateError
} from './actions'

export interface StationState {
  // 是否允许轮询, 暂时没有用
  readonly allowPolling: boolean
  // 是否允许跨链的token进行swap
  readonly allowSwap: boolean
  // 是否允许station-liquidity， true - 允许station
  readonly allowLiquidity: boolean
  // station时，用户选择的需要筛选映射币出来的链ID
  readonly stationChainId: number
  // station是否开启
  readonly allowStation: boolean
  // 上一次同步的时间
  readonly lastUpdateTime: number
  // 是否在同步
  readonly ifSync: boolean
  readonly error: any
}

const initialState: StationState = {
  allowPolling: true,
  allowSwap: true,
  allowLiquidity: true,
  allowStation: true,
  error: null,
  stationChainId: Number(window.localStorage.getItem('STATION_CHAIN_ID')) || 56,
  lastUpdateTime: 9999999999999,
  ifSync: false
}

export default createReducer<StationState> (initialState, (builder) =>
  builder
    .addCase (
      updateAllowPolling,
      (state, { payload: { status } }) => {
        return {
          allowPolling: status,
          allowSwap: state.allowSwap,
          allowLiquidity: state.allowLiquidity,
          allowStation: state.allowStation,
          stationChainId: state.stationChainId,
          lastUpdateTime: state.lastUpdateTime,
          ifSync: state.ifSync,
          error: state.error
        }
      }
    ).addCase (
    updateAllowSwap,
    (state, { payload: { status } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: status,
        allowLiquidity: state.allowLiquidity,
        allowStation: state.allowStation,
        stationChainId: state.stationChainId,
        lastUpdateTime: state.lastUpdateTime,
        ifSync: state.ifSync,
        error: state.error
      }
    }
  ).addCase (
    updateAllowLiquidity,
    (state, { payload: { status } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: state.allowSwap,
        allowLiquidity: status,
        allowStation: state.allowStation,
        stationChainId: state.stationChainId,
        lastUpdateTime: state.lastUpdateTime,
        ifSync: state.ifSync,
        error: state.error
      }
    }
  ).addCase (
    updateAllowStation,
    (state, { payload: { status } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: state.allowSwap,
        allowLiquidity: state.allowLiquidity,
        allowStation: status,
        stationChainId: state.stationChainId,
        lastUpdateTime: state.lastUpdateTime,
        ifSync: state.ifSync,
        error: state.error
      }
    }
  ).addCase (
    updateStationChainId,
    (state, { payload: { id } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: state.allowSwap,
        allowLiquidity: state.allowLiquidity,
        allowStation: state.allowStation,
        stationChainId: id,
        lastUpdateTime: state.lastUpdateTime,
        ifSync: state.ifSync,
        error: state.error
      }
    }
  ).addCase (
    updateLastUpdateTime,
    (state, { payload: { t } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: state.allowSwap,
        allowLiquidity: state.allowLiquidity,
        allowStation: state.allowStation,
        stationChainId: state.stationChainId,
        lastUpdateTime: t,
        ifSync: state.ifSync,
        error: state.error
      }
    }
  ).addCase (
    updateSync,
    (state, { payload: { status } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: state.allowSwap,
        allowLiquidity: state.allowLiquidity,
        allowStation: state.allowStation,
        stationChainId: state.stationChainId,
        lastUpdateTime: state.lastUpdateTime,
        ifSync: status,
        error: state.error
      }
    }
  ).addCase (
    updateError,
    (state, { payload: { status } }) => {
      return {
        allowPolling: state.allowPolling,
        allowSwap: state.allowSwap,
        allowLiquidity: state.allowLiquidity,
        allowStation: state.allowStation,
        stationChainId: state.stationChainId,
        lastUpdateTime: state.lastUpdateTime,
        ifSync: state.ifSync,
        error: status
      }
    }
  )
)
