import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import Swap from './index'

// Redirects to swap but only replace the pathname
export function RedirectPathToSwapOnly({ location }: RouteComponentProps) {
  return <Redirect to={{ ...location, pathname: '/swap' }} />
}


export function RedirectSwapDuplicateTokenIds(props: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const {
    match: {
      params: { currencyIdA, currencyIdB },
    },
  } = props
  const {chainId} = useWeb3React()

  if (currencyIdA?.toLowerCase() === currencyIdB?.toLowerCase()) {
    return <Redirect to={`/swap/${chainId}/${currencyIdA}`} />
  }

  // @ts-ignore
  return <Swap {...props} />
}
