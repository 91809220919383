import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react'
import { ThemeContext } from 'styled-components'
import { ChainId, Pair } from '@glhf-libs/sdk'
import { useTranslation } from 'react-i18next'
import { Button, CardBody, Text } from '@hurricaneswap/uikit'
import { Link, useLocation } from 'react-router-dom'
// import Question from 'components/QuestionHelper'
import MigrateCard from 'components/MigrateCard'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { TYPE } from 'components/Shared'
import { TransparentCard as LightCard } from 'components/Card'
import { Rowleft } from 'components/Row'
import { AutoColumn, ColumnCenter } from 'components/Column'
import { useAddPopup } from 'state/application/hooks'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'

import { useActiveWeb3React } from 'hooks'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import TranslatedText from 'components/TranslatedText'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import { useSwitchChain } from '../../hooks/Station'


const { body: Body } = TYPE

export default function Migrate() {
  const { inputCurrency: currencyIdA, outputCurrency: currencyIdB }: any = useParsedQueryString()
  const theme = useContext(ThemeContext)
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()
  const addPopup = useAddPopup()
  // addPopup(
  //   {
  //     txn: {
  //       hash: 'dddd',
  //       summary: '00000',
  //       success: true
  //     },
  //   }, '125')
  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
    [trackedTokenPairs]
  )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens,
  ])
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  const pathName = useMemo(() => {
    const res = (`/add${currencyIdA ? `/${currencyIdA}` : ''}${currencyIdB ? `/${currencyIdB}` : ''}`)
    return res
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyIdA, currencyIdB])
  const onlyOnAvax = useOnlyOnAvax()
  const login = useSwitchChain()

  useEffect(() => {
    if (chainId !== ChainId.AVAX_MAINNET && chainId !== ChainId.AVAX_TESTNET) {
      login(process.env.REACT_APP_ENV === 'MAIN' ? ChainId.AVAX_MAINNET : ChainId.AVAX_TESTNET)
    }
  }, [chainId, login])

  // modal and loading
  const [{ attemptingTxn, txHash, pendingText, migrateErrorMessage }, setMigrateState] = useState({
    attemptingTxn: false,
    pendingText: '',
    txHash: undefined,
    migrateErrorMessage: null
  })

  const [isOpen, setIsOpen] = useState(false)
  const onDismiss = () => {
    setIsOpen(false)
    setMigrateState(() => ({
      attemptingTxn: false,
      pendingText: '',
      txHash: undefined,
      migrateErrorMessage: null
    }))
  }
  const onOpen = () => {
    setIsOpen(true)
  }
  const [toasts, setToasts]: any = useState([]);
  const onRemove = () => { setToasts([]) }
  const resultHandler = (res) => {
    if (res.showPopup) {
      setIsOpen(true)
    }
    if (res.hidePopup) {
      setIsOpen(false)
    }
    setMigrateState(() => ({
      attemptingTxn: res.attemptingTxn,
      pendingText: res.pendingText,
      txHash: res.txHash,
      migrateErrorMessage: res.migrateErrorMessage
    }))

    if (res.popupSummary) {
      addPopup(
        {
          txn: {
            hash: res.txHash,
            summary: res.popupSummary,
            success: !!res.status
          },
        }, res.txHash)
    }
  }
  return (
    <>
      <AppBody>
        <MigrateModal
          isOpen={isOpen}
          onDismiss={onDismiss}
          attemptingTxn={attemptingTxn}
          hash={txHash}
          pendingText={pendingText}
          migrateErrorMessage={migrateErrorMessage}
        />
        <PageHeader title="migrate" />
        <AutoColumn gap="lg" justify="center">
          <CardBody p='24px 24px 48px' style={{ width: '100%' }}>
            <AutoColumn gap="12px" style={{ width: '100%' }} >

              {!account ? (
                <LightCard padding="40px">
                  <Body color={theme.colors.textDisabled} textAlign="center">
                    Connect to a wallet to view your liquidity.
                  </Body>
                </LightCard>
              ) : v2IsLoading ? (
                <LightCard padding="40px">
                  <Body color={theme.colors.textDisabled} textAlign="center">
                    <Dots>Searching LP Token ...</Dots>
                  </Body>
                </LightCard>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <>
                  {allV2PairsWithLiquidity.map((v2Pair) => (
                    <MigrateCard key={v2Pair.liquidityToken.address} pair={v2Pair} resultHandler={resultHandler} />
                  ))}
                </>
              ) : (
                <LightCard padding="40px">
                  <Body color={theme.colors.textDisabled} textAlign="center">
                    <TranslatedText translationId={104}>No LP Token Found!</TranslatedText>
                  </Body>
                </LightCard>
              )}
              <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                {/* eslint-disable-next-line */}
                {t("Can’t find your LP token?")}
              </Text>
              {/* TO DO: Import */}
              <Button id="import-pool-link" as={Link} to="/find" style={{ width: '100%' }} variant="secondary">
                {t('Import it')}
              </Button>
              <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                {t("Can’t find your LP token? Withdraw your LP token in Farm-V1 first.")}
              </Text>
              <a href="https://farm-v1.hurricaneswap.com" >
                <Button style={{ width: '100%' }} variant="secondary">
                  {t('Go Farm-V1')}
                </Button>
              </a>
            </AutoColumn>
          </CardBody>
        </AutoColumn>
      </AppBody>
    </>
  )
}

function MigrateModal({
  isOpen,
  pendingText,
  attemptingTxn,
  hash,
  migrateErrorMessage,
  onDismiss
}: {
  isOpen: boolean
  pendingText: string | undefined
  attemptingTxn: boolean
  hash: string | undefined
  migrateErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const ErrorModal = useCallback(() => migrateErrorMessage ? (<TransactionErrorContent onDismiss={onDismiss} message={migrateErrorMessage} />) : null, [migrateErrorMessage, onDismiss])
  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={hash}
      content={ErrorModal}
      pendingText={pendingText}
    />
  )
}