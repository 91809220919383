import React from "react";
import styled from "styled-components";
import { SvgProps } from "@hurricaneswap/uikit"

const StyledLogoDiv = styled.div`
  // position: absolute;
  // right:60px;
  margin-left: 53px;
`;

const Icon: React.FC<SvgProps> = () => {

  return (
    <StyledLogoDiv>
        <img width='28px' alt='logo' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKHSURBVHgBtZY7bxNBEMdn9/Zs79nEjpCVgggJgSiQKGjSgoREgUQX6AiPggZR0FDnGxApoqDhERoELXKdgoKGhoICKSJCUEQBmUjm1vbt7TK79iWHn+c985dO99i9+d3OzewMgSxqPChWmzKIVFzSUcR0kVLzmHSUoiWvy7TfPVhkIVzd7EwzRSaObt0sc01qmvkMMojISAqif8Paqz+zAdcvseD02bqicQEcZMFfv+3B+racCqw/uV5p8cpi4jZXEaqU8Go/4cZjkX7u/TPrzUOuClAHRgjklSaEqU5ZXj4TQ+NzdxiIbuScL80FlpIf+CV5vhbC9q4y94du46dOLuV14yhpRamJh+S+B8BozBqJLrLBh4xDoAl9mIMirc9JEn9SRN0dHEsYDJ7fLmnm5V6dgWFkvsYAWBg1bj2IGwiFAueQUwOwDarps5ETlQxomUROyT0O5mlvY9zcoBmXqGrHzsBZYEaaRYyOSwWJPx6P9xqNzgNmgZgiE/OOgF5WaHQQ6gJLRE2JGTXAej/eGFpIQ/PAzP7qFVZXyppob+TXAP2AY+byIp6vYX7t48a36QIzagPteNHqCiZIXBw3KQ0lQK4grOgCM5LVQkihK8S0iX3jCcAJZkVZaCsDf7l2IsteSrRc1oR9BwfZonxr64eNUtESB1lecoWlGb20uP+2RZXXhf8kszrDOAKiwp0v++NSJBcMbdr+pq+jxMeGR4hWE+YsEfm/0s3UcDvx9F7A/eh47ibKrMwsoO/KRMMJ/+5jJC/UQr96LADqOUFNPIjdnT141GgPfcjEN7Fl5BVenakRNtE4sKrswETYFdhCjbWTYzlLt/rC9yV6om03kDsv2tNM/QVr6EC8U40UyQAAAABJRU5ErkJggg=='/>

    </StyledLogoDiv>

  );
};

export default Icon;

