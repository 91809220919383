import styled from 'styled-components'

const Pane = styled.div`
  // border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border: 1px solid rgba(77, 60, 85, 0.25);
  border-radius: 12px;
  padding: 16px;
`

export default Pane
