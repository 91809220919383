import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { PortisConnector } from '@web3-react/portis-connector'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'
import { BscConnector } from './bsc/bscConnector'


const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID
const IFMAINNET = process.env.REACT_APP_ENV === 'MAIN'
const urls = IFMAINNET
  ? {
    [parseInt('43114', 10)]: 'https://api.avax.network/ext/bc/C/rpc',
    [parseInt('56', 10)]: 'https://bsc-dataseed1.ninicoin.io',
    [parseInt('128', 10)]: 'https://http-mainnet.hecochain.com'
  } : {
    [parseInt('43113', 10)]: 'https://api.avax-test.network/ext/bc/C/rpc',
    [parseInt('97', 10)]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    [parseInt('256', 10)]: 'https://http-testnet.hecochain.com'
  }

const supportedChainIds = IFMAINNET ? [43114, 56, 128] : [43113, 97, 256]

export const NETWORK_CHAIN_IDS: number[] = IFMAINNET ? [43114, 56, 128] : [43113, 97, 256]

export const network = new NetworkConnector({
  // @ts-ignore
  urls,
  defaultChainId: IFMAINNET ? 43114 : 43113
})

let networkLibrary: Web3Provider | undefined

export function getNetworkLibrary(): Web3Provider {
  // eslint-disable-next-line no-return-assign
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
  supportedChainIds
})

export const bsc = new BscConnector({ supportedChainIds: [97] })



// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: {
    [parseInt('43113', 10)]: 'https://api.avax-test.network/ext/bc/C/rpc',
  },
  supportedChainIds: [43113],
  bridge: 'https://pancakeswap.bridge.walletconnect.org',
  qrcode: true
})


// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1
})

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1]
})

// mainnet only
export const walletlink = new WalletLinkConnector({
  // @ts-ignore
  url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  appName: 'Uniswap',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg'
})
