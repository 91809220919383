import React, { useCallback, useMemo, useState } from 'react'
import {createPortal} from 'react-dom'

export default function Portal({ children, id = 'root' }) {

  const _node: any = useMemo(()=>document.getElementById(id), [id])
  return createPortal(
    <>
      {children}
    </>,
    _node
  );
}
