import React, { useCallback, useState } from 'react'
import { X } from 'react-feather'
import { Button, Text, Flex, PancakeToggle } from '@hurricaneswap/uikit'
import styled from 'styled-components'
import { useAudioModeManager } from 'state/user/hooks'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'
import Modal from '../Modal'

const ModalWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding: 32px 22px;
`

const Pointer = styled.div`
  cursor: pointer;
`

type SettingsModalProps = {
  isOpen: boolean
  onDismiss?: () => void
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal = ({ isOpen, onDismiss = defaultOnDismiss }: SettingsModalProps) => {
  const [listView, setListView] = useState<boolean>(false)

  const handleClickChangeList = useCallback(() => {
    setListView(true)
  }, [])
  const handleClickBack = useCallback(() => {
    setListView(false)
  }, [])

  const column = true
  const [audioPlay, toggleSetAudioMode] = useAudioModeManager()

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <Flex style={{ width: '100%' }} flexDirection="row" mb={26} justifyContent="space-between" alignItems="center">
          <Text>Transaction Settings</Text>
          <Pointer onClick={onDismiss}>
            <X width={20} height={20} />
          </Pointer>
        </Flex>
        <SlippageToleranceSetting />
        <TransactionDeadlineSetting />
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Text>Flippy sounds</Text>
          </Flex>
          <PancakeToggle checked={audioPlay} onChange={toggleSetAudioMode} scale="md" />
        </Flex>
      </ModalWrapper>
    </Modal>
  )
}

export default SettingsModal
