import React, { useMemo } from 'react'
import { Currency, ETHER, Token } from '@glhf-libs/sdk'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import CurrencyList from '../SearchModal/CurrencyList'
import { useAllTokens } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useTokenComparator } from '../SearchModal/sorting'
import { useUserAddedTokens } from '../../state/user/hooks'

const CoinListContainer = styled.div`
  // border: 1px solid black;
  // height: 78px;
  width: 100%;
  border-radius: 8px;
  color: ${({theme})=>(theme.colors.text)};
  background: ${({theme})=>(theme.isDark ? 'rgba(77, 60, 85, 0.45)' : 'rgba(141, 128, 213, 0.1)')};
  font-size: 12px!important;
`

const Wrapper = styled.div`
  padding: 12px 16px;
`

const UserTokens = () => {
  const { chainId, account } = useActiveWeb3React()

  const {pathname} = useLocation()

  const allTokens = useAllTokens()
  // @ts-ignore
  const userTokens = useUserAddedTokens()

  const tokenComparator = useTokenComparator(false)

  const filteredTokens: Token[] = useMemo(() => {
    const userAddedAddressArr = userTokens.map(i=>i.address)
    return Object.values(allTokens).filter(i=>!userAddedAddressArr.includes(i.address))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTokens])

  const filteredSortedTokens: any[] = useMemo(() => {
    const sorted = filteredTokens.sort(tokenComparator)
    const ETH = !pathname.includes('station') ? [Currency.ETHER] : []
    return [
      ...ETH,
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase())
    ]
  }, [filteredTokens, tokenComparator, pathname])

  // const itemData = useMemo(() => (showETH ? [Currency.ETHER, ...currencies] : [...currencies]), [currencies, showETH])

  return <Wrapper>
    <CoinListContainer>
      {
        (filteredSortedTokens && account && filteredSortedTokens.length > 0)
          ? (
            <CurrencyList
              height={120}
              showETH={false}
              currencies={filteredSortedTokens}
              onCurrencySelect={()=>null}
              otherCurrency={null}
              selectedCurrency={null}
              fixedListRef={undefined}
              itemSize={40}
              type='user'
            />
          )
          : (
            <div style={{minHeight: '78px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>You do not have any tokens</div>
          )
      }
    </CoinListContainer>

  </Wrapper>
}

export default UserTokens
