import React, { useContext } from 'react'
import { Flex, Text, Skeleton } from '@hurricaneswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import hurricane from '../../assets/imgs/hurricane.png'
import useMobile from '../../hooks/useMobile'


const Star = styled.div`
    &::before {
        content: '';
        position: absolute;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADASURBVHgBjZDBDcIwDEVtN+XCodmAIIHEsaMwQiUYhFHYBCSQuDJC2CAXDrTUxlU5OlJ8iWLn5ft/AKO6cPGQKbKazrk2B5mAiPglNk0xgEjhU73XxYBqeBYK1sSZzxEDgSRTfTJX14swwtiSYNB1VgKyR8AJuIrwSz09iao4DH3UlbyaZI8M84+iDUA95jsrKAT/xDJpH7b383FzOxV7AJbEQLEYQMSIwCZgxsrISc2nYgVNKw7fPkJpdbtHyM1+MbVFVwJHUAQAAAAASUVORK5CYII=') no-repeat;
        width: 16px;
        height: 16px;
        display:block;

`

const CardContainer = styled.div<{ ifMobile: boolean }>`
  max-width: 710px;
  width: 100%;
  padding: ${({ ifMobile }) => (ifMobile ? '0 10px' : '0')};
  box-sizing: border-box;
  // height: 285px;
  overflow: hidden;
  position: relative;
`
const Card = styled.div<{ ifMobile?: boolean }>`
  width: 100%;
  height: 100%;
  // padding: 35px 5px 0 26px;
  padding: 5px;

`

const MigrateDes = () => {
  const ifMobile = useMobile()
  const theme = useContext(ThemeContext)
  return (
    <CardContainer ifMobile={ifMobile}>
      <Card ifMobile={ifMobile}>
        <Flex>
          <Flex alignItems={ifMobile ? 'center' : 'flex-start'} flexDirection='column' pt='30px' pl='21px' pr='21px' style={{ boxSizing: 'content-box' }}>
            <Text mb='20px' fontSize='32px'>Migrate Liquidity</Text>

            <Star />
            {/* @ts-ignore */}
            <Text textAlign={ifMobile ? 'center' : 'left'} mb='10px' lineHeight='24px' color={() => theme.isDark ? '#e4e2e7' : 'rgba(77, 60, 85, 0.65);'} style={{ 'textIndent': '20px' }}>
              For each LP token shown on this page, select and enter the amount you wish to migrate, then click “Migrate” to remove your liquidity from HurricaneSwap-V1 and add to HurricaneSwap-V2 directly as well as refund the redundant asset.
            </Text>

            <Star />
            {/* @ts-ignore */}
            <Text textAlign={ifMobile ? 'center' : 'left'} mb='25px' lineHeight='30px' color={() => theme.isDark ? '#e4e2e7' : 'rgba(77, 60, 85, 0.65);'} style={{ 'textIndent': '20px' }}>
              If you have deposited LP tokens in Farm-V1, it won’t be shown on this page, withdraw them first.
            </Text>

          </Flex>
        </Flex>
      </Card>
    </CardContainer>

  )
}


export default MigrateDes