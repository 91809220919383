/* eslint-disable */
const display = process.env.REACT_APP_ENV === 'MAIN' ? false : true

export const config = [{
  label: "Home",
  href: "/",
},
{
  label: "Swap",
  href: "/swap",
},
{
  label: "Migrate",
  href: "/migrate"
},
{
  label: "Stake",
  href: "/stake",
},
{
  label: "Farm",
  href: 'https://farm-v1.hurricaneswap.com/',
},
// {
//   label: "Transfer",
//   children: [
//     {
//       label: "Station",
//       href: "/stationpool",
//     }
//   ]
// },
{
  label: "Info",
  children: [
    {
      label: "Overview",
      href: "/info/overview",
    },
    {
      label: "Pairs",
      href: "/info/pairs",
    },
    {
      label: "Tokens",
      href: "/info/tokens",
    },
  ]
},
  // {
  //   label: "Info",
  //   disabled: true
  // },
];

export default config
