import { useCallback, useEffect } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector
} from '@web3-react/walletconnect-connector'
import { useModal } from '@hurricaneswap/uikit'
import warning from 'tiny-warning'

import { setupNetwork } from '../utils'
import { bsc, injected, network, walletconnect } from '../connectors'
import { baseChainID } from '../constants'


const useAuth = () => {
  const { connector: web3Connect, activate, deactivate } = useWeb3React()

  const login = useCallback((connectorID = window.localStorage.getItem('connectorId'), chainId = baseChainID) => {
    const connector = connectorID === 'walletconnect' ? walletconnect : connectorID === 'bsc' ? bsc : injected
    window.localStorage.removeItem('walletconnect')

    if (connector) {
      activate(connector, async (error: Error) => {
        window.localStorage.removeItem('connectorId')

        if (error instanceof UnsupportedChainIdError) {
          console.error('Unsupported Chain Id', 'Unsupported Chain Id Error. Check your chain Id.')
          warning(false, 'eth_requestAccounts was unsuccessful, falling back to enable')

          const hasSetup = await setupNetwork(chainId)
          if (hasSetup) {
            activate(connector)
          }


        } else if (error instanceof NoEthereumProviderError) {
          console.error('Provider Error', 'No provider was found')
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector
            walletConnector.walletConnectProvider = null
          }
          console.error('Authorization Error', 'Please authorize to access your account')
        } else {
          console.error(error.name, error.message)
        }

        // if (error instanceof UnsupportedChainIdError) {
        //   console.error ('Unsupported Chain Id Error. Check your chain Id.')
        //
        //   const hasSetup = await setupNetwork (connectorID)
        //   if (hasSetup) {
        //     activate (connector)
        //   }
        //
        // } else if (
        //   error instanceof UserRejectedRequestErrorInjected
        // ) {
        //   console.error ('Please authorize to access your account')
        // } else {
        //   console.error (error.message)
        // }
      })
    } else {
      console.error('The connector config is wrong')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate])


  const logout = useCallback(() => {
    deactivate()
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      walletconnect.close()
      walletconnect.walletConnectProvider = null
    }
  }, [deactivate])

  return { login, logout }
}

export default useAuth
