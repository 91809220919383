import { ETHER } from '@glhf-libs/sdk'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from '../index'
import { useActiveWeb3React } from '../../hooks'
import { updateInputCurrency, updateOutputCurrency } from './reducer'
import {useBaseCoin} from '../../hooks/useBaseCoin'
import { currencyId } from '../../utils/currencyId'

export function useTrading(): AppState['trading'] {
  return useSelector<AppState, AppState['trading']>((state) => state.trading)
}

export function useUpdateInputCurrency() {
  const dispatch = useDispatch<AppDispatch>()
  const { chainId } = useActiveWeb3React()
  const baseCoin = useBaseCoin(ETHER, chainId)
  return useCallback((payload)=>{
    if(typeof payload === 'string'){
      if(payload === 'undefined'){
        dispatch(updateInputCurrency(''))
      }else if(payload === 'AVAX'){
        dispatch(updateInputCurrency(baseCoin))
      } else{
        dispatch(updateInputCurrency(payload))
      }
    }else {
      try {
        const c = currencyId(payload)
        if(c === 'AVAX'){
          dispatch(updateInputCurrency(baseCoin))
        }else{
          dispatch(updateInputCurrency(c))
        }
      }catch (e) {
        dispatch(updateInputCurrency(''))
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, baseCoin])
}

export function useUpdateOutputCurrency() {
  const dispatch = useDispatch<AppDispatch>()
  const { chainId } = useActiveWeb3React()
  const baseCoin = useBaseCoin(ETHER, chainId)
  return useCallback((payload)=>{
    if(typeof payload === 'string'){
      if(payload === 'undefined'){
        dispatch(updateOutputCurrency(''))
      }else if(payload === 'ETH'){
        dispatch(updateOutputCurrency(baseCoin))
      }else{
        dispatch(updateOutputCurrency(payload))
      }
    }else {
      try {
        const c = currencyId (payload)
        if (c === 'ETH') {
          dispatch (updateOutputCurrency (baseCoin))
        } else {
          dispatch (updateOutputCurrency (c))
        }
      } catch (e) {
        dispatch (updateOutputCurrency (''))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, baseCoin])

}
