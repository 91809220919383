import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'GTText', sans-serif;
  }
  body {
    // background-color: ${({ theme }) => theme.colors.background};
    background-color: #000;
    img {
      height: auto;
      max-width: 100%;
    }
  }
  button#wallet-connect-metamask ~ button{
    display: none!important;
  }
  @keyframes hue {
    0% {
      filter: hue-rotate(0deg);
    }
    50% {
      filter: hue-rotate(180deg);
    }
    100% {
      filter: hue-rotate(0deg);
    }
  }
`

export default GlobalStyle
