import { ChainId, ETHER } from '@glhf-libs/sdk'
import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'

export function useBaseCoin (currency, chainId) {
  return useMemo (() => {
    return (
      currency === ETHER && (chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET))
      ? 'BNB'
      : (currency === ETHER && (chainId === ChainId.AVAX_MAINNET || chainId === ChainId.AVAX_TESTNET))
        ? 'AVAX'
        : (currency === ETHER && (chainId === ChainId.HECO_MAINNET || chainId === ChainId.HECO_TESTNET))
          ? 'HT'
          : currency?.symbol === 'Currency' ? 'AVAX' : currency?.symbol
  }, [chainId, currency])
}

export function useNetworkName () {
  const { chainId } = useWeb3React ()
  return useMemo (() => {
    return (
      (chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET))
      ? 'Binance Smart Chain'
      : ((chainId === ChainId.AVAX_MAINNET || chainId === ChainId.AVAX_TESTNET))
        ? 'AVAX'
        : ((chainId === ChainId.HECO_MAINNET || chainId === ChainId.HECO_TESTNET))
          ? 'Heco'
          : 'Unknown Network'
  }, [chainId])
}


export default useBaseCoin
