import { useLocation } from 'react-router'
import { useMemo } from 'react'
import { useActiveWeb3React } from './index'

export default ()=>{
  const { account, chainId }:any = useActiveWeb3React()
  const {pathname} = useLocation()
  return useMemo(()=>{
    if(!pathname.includes('station') && [56, 97, 128, 256].includes(chainId)){
      return 'Cross-chain token swap does not support yet.'
    }
    return null
  }, [chainId, pathname])
}
