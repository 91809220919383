import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { ETHER } from '@glhf-libs/sdk'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { Button, Text, Box, Flex, Skeleton } from '@hurricaneswap/uikit'
import { useAsyncByAwait } from '../../hooks/useAyncByAwait'
import TradingView from '../TradingViewCoinGecko'

import { useAllTokens } from '../../hooks/Tokens'
import { isAddress } from '../../utils'
import { AppState } from '../../state'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import { useUpdateInputCurrency } from '../../state/trading/hooks'

const MarginTop = styled.section`
  margin-top: 20px;
`

const MarginBottom = styled.section`
  margin-bottom: 20px;
`

const UlGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  justify-items: start;
  align-items: center;
  border-top: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }}; 
  li{
    border-left: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    display: inline-block;
    border-bottom: 1px solid ${({ theme }) => { return theme.colors.tokenInfoBorder }};
    &:nth-of-type(3n-2){
      padding-left: 0;
      padding-right: 0;
      border-left: none;
    }
  }
`


const TokenInfo = () => {
  const { inputCurrency, outputCurrency } = useSelector<AppState, AppState['trading']>((state) => state.trading)

  const fetchTokenInfo = () => (axios.get(`../mock/info.json`))
  const fetchQuote = () => (axios.get('../mock/quotes.json'))
  const [info, setInfo]: any = useState(null)
  const [quote, setQuote]: any = useState(null)
  const [description, setDescription]: any = useState(null)
  const { isLoading: fetchTokenInfoLoading, load: fetchTokenInfoLoad, result: fetchTokenInfoResult, error: fetchTokenInfoError }: any = useAsyncByAwait(fetchTokenInfo)
  const { isLoading: fetchTokenQuoteLoading, load: fetchTokenQuoteLoad, result: fetchTokenQuoteResult, error: fetchTokenQuoteError }: any = useAsyncByAwait(fetchQuote)
  const theme = useContext(ThemeContext)
  const serializedTokensMap = useAllTokens()
  const { connector, chainId } = useWeb3React()
  const inputLoad = useUpdateInputCurrency()
  const baseCoin = useBaseCoin(ETHER, chainId)

  const fetchQuote1 = async () => {
    if (info) {
      const originData = await axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${info[0].slug}&order=market_cap_desc&per_page=100&page=1&sparkline=false`);
      const volume = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${info[0].slug}&vs_currencies=usd&include_24hr_vol=true`)
      const des = await axios.get(`https://api.coingecko.com/api/v3/status_updates?category=general&project_type=coin&ids=${info[0].slug}`)

      //  https://api.coingecko.com/api/v3/status_updates?category=general&project_type=coin
      // console.log(volume?.data[info[0].slug]?.usd_24h_vol,'1234567')
      setQuote({
        'priceChange24': originData?.data[0]?.price_change_24h,
        'volumeChange24': volume?.data[info[0].slug]?.usd_24h_vol, // 这个是这个嘛？
        'roi': originData?.data[0]?.roi?.percentage,
        'supply': originData?.data[0]?.circulating_supply,
        'marketCap': originData?.data[0]?.market_cap,
        'rank': originData?.data[0]?.market_cap_rank

      })
      const direction = originData?.data[0]?.price_change_24h > 0 ? 'up' : 'down'
      const market = await axios.get(`https://api.coingecko.com/api/v3/coins/${info[0].slug}?localization=false&developer_data=false`);
      const toBuy = market?.data.tickers[0].market.name
      // console.log(market)
      const msg = ` ${originData?.data[0]?.name} price today is $${originData?.data[0]?.current_price?.toFixed(2)} 
                with a 24-hour trading volume of $${volume?.data[info[0].slug]?.usd_24h_vol.toFixed(2)}.
                 ${originData?.data[0]?.symbol} price is ${direction} ${originData?.data[0]?.price_change_24h?.toFixed(2)}% in the last 24 hours. 
                 It has a circulating supply of ${originData?.data[0]?.circulating_supply?.toFixed(2)} 
                 ${originData?.data[0]?.symbol} coins and a total supply of ${originData?.data[0]?.total_supply?.toFixed(2)}. `

      setDescription(msg)
    }

  }


  // 切换链时候更新下basecoin
  useEffect(() => {
    inputLoad(baseCoin)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseCoin])

  useEffect(() => {
    fetchTokenQuoteLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTokenQuoteLoad, inputCurrency, outputCurrency])

  useEffect(() => {
    fetchTokenInfoLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTokenInfoLoad, inputCurrency, outputCurrency])

  useEffect(() => {
    if (fetchTokenInfoResult) {
      // const [_currencyA, _currencyB] = pairOrTrading
      const currencyA: any = isAddress(inputCurrency) ? serializedTokensMap[inputCurrency]?.symbol : inputCurrency
      const currencyB: any = isAddress(outputCurrency) ? serializedTokensMap[outputCurrency]?.symbol : outputCurrency
      const {
        [currencyA]: tokenA = { name: currencyA, symbol: currencyA, platform: { token_address: currencyA } },
        [currencyB]: tokenB = { name: currencyB, symbol: currencyB, platform: { token_address: currencyB } }
      } = fetchTokenInfoResult?.data?.data
      setInfo([tokenA, tokenB])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTokenInfoResult, serializedTokensMap])

  useEffect(() => {
    fetchQuote1()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])




  useEffect(() => {
    if (fetchTokenQuoteResult) {
      // const [_currencyA, _currencyB] = pairOrTrading
      const currencyA: any = isAddress(inputCurrency) ? serializedTokensMap[inputCurrency]?.symbol : inputCurrency
      const currencyB: any = isAddress(outputCurrency) ? serializedTokensMap[outputCurrency]?.symbol : outputCurrency
      const { [currencyA]: tokenA, [currencyB]: tokenB } = fetchTokenQuoteResult?.data?.data
      setQuote([tokenA, tokenB])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchTokenQuoteResult, serializedTokensMap])

  const numberWithCommas = (x: any) => {
    return (typeof x === "string") ? x.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
  }

  const SingleCoinInfo: any = useCallback(() => (
    <>{
      info.filter(i => i.name).map((i, index) => i && (
        <div key={i.name}>
          <Text mb='12px' fontSize='24px' bold color={theme.colors.text}>About {i.name}</Text>
          <Text mb='30px' fontSize='16px' color={theme.colors.text}>{description}</Text>


          <UlGrid theme={theme}>
            <li>
              <Text mb='4px' fontSize='12px' color={theme.colors.text}>Market Cap Rank</Text>
              <Text style={{ wordBreak: 'break-all' }}
                fontSize='16px'>{quote?.rank || '-'}</Text>
            </li>
            <li>
              <Text mb='4px' fontSize='12px' color={theme.colors.text}>Market Cap</Text>
              <Text style={{ wordBreak: 'break-all' }}
                fontSize='16px'>{quote?.marketCap && numberWithCommas(quote?.marketCap.toFixed(2)) || '-'}</Text>
            </li>
            <li>
              <Text mb='4px' fontSize='12px' color={theme.colors.text}>Circulating Supply</Text>
              <Text style={{ wordBreak: 'break-all' }}
                fontSize='16px'>{quote?.supply && numberWithCommas(quote?.supply.toFixed(2)) || '-'}</Text>
            </li>
            <li>
              <Text mb='4px' fontSize='12px' color={theme.colors.text}>All-Time ROI</Text>
              <Text style={{ wordBreak: 'break-all' }}
                fontSize='16px'>{quote?.roi && numberWithCommas(quote?.roi.toFixed(2)) || '-'}</Text>
            </li>
            <li>
              <Text mb='4px' fontSize='12px' color={theme.colors.text}>24h Volume</Text>
              <Text style={{ wordBreak: 'break-all' }}
                fontSize='16px'>{quote?.volumeChange24 && numberWithCommas(quote?.volumeChange24.toFixed(2)) || '-'}</Text>
            </li>
            <li>
              <Text mb='4px' fontSize='12px' color={theme.colors.text}>24h percent change</Text>
              <Text style={{ wordBreak: 'break-all' }}
                fontSize='16px'>{quote?.priceChange24 ? `${numberWithCommas(quote?.priceChange24.toFixed(2))}%` : '-'}</Text>
            </li>
          </UlGrid>
        </div>
      ))
    }</>
  ), [info, quote, theme, description])

  return (
    <div>
      <MarginBottom>
        {
          (fetchTokenInfoResult && info) ? (
            <Flex alignItems='center' flexWrap='wrap'>
              {
                info.map((i, index) => (
                  i && (
                    <div key={i.symbol}>
                      <Flex alignItems='center'>
                        {i.name && <Text bold fontSize='32px' color={theme.colors.text}
                          mr='12px'>{i.name}</Text>}

                        {index === 0 && info.filter(j => j?.symbol).length > 1 && <Text color={theme.colors.text} bold fontSize='32px' mr='8px'>/</Text>}
                      </Flex>
                      {i.symbol && <Text bold fontSize='18px' color={theme.colors.text}
                        mr='12px'>({i.symbol})</Text>}
                    </div>
                  )
                ))
              }
            </Flex>
          ) : (<Skeleton animation="waves" variant="rect" width="100%" height="32px" />)
        }
      </MarginBottom>

      <TradingView defaultPrice={0} token={info} tokenName={info ? [info[0]?.slug, info[1]?.slug] : []} tokenId={info ? [info[0]?.id, info[1]?.id] : []} />

      <MarginTop>
        {
          (fetchTokenInfoResult && info) ? (
            info.filter(i => i.name).length === 1
              ? <SingleCoinInfo />
              : <Text />
          ) : (<Skeleton animation="waves" variant="rect" width="100%" height="300px" />)
        }
      </MarginTop>
    </div>
  )
}

export default TokenInfo
