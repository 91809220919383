import React, { Suspense, useEffect, useState, lazy } from 'react'
import { Box, Flex, Text as UIKitText } from '@hurricaneswap/uikit'
import {
  HashRouter,
  Link as HistoryLink,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import Column from 'components/Column'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'

import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
// import { RedirectStationDuplicateTokenIds, RedirectStationOldAddLiquidityPathStructure } from './StationAddLiquidity/redirects'
// import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
// import { RedirectStationOldRemoveLiquidityPathStructure } from './StationRemoveLiquidity/redirects'

import Home from './Home'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import LPFinder from './LPFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Info from './Info'
import Swap from './Swap'
import Test from './Test'
// import StationPool from './StationPool'
// import StationAddLiquidity from './StationAddLiquidity'
// import StationPoolFinder from './StationPoolFinder'
// import StationRemoveLiquidity from './StationRemoveLiquidity'
import Bridge from './Bridge'
import Stake from './Stake'
import Migrate from './Migrate'
import Liquidity from './Liquidity'


import { RedirectSwapDuplicateTokenIds } from './Swap/redirects'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import Menu from '../components/Menu'
import TokenInfo from '../components/TokenInfo'
// import StationTokenInfo from '../components/StationTokenInfo'
import StakeDec from '../components/StakeInfo'
import MigrateDes from '../components/MigrateDes'
import Footer from '../components/PageFooter'
import useMobile from '../hooks/useMobile'
import hurricane from '../assets/imgs/hurricane.png'
import '../style/index.css'

// const Home = lazy(() => import('./Home'));
// const AddLiquidity = lazy(() => import('./AddLiquidity'));
// const Pool = lazy(() => import('./Pool'));
// const PoolFinder = lazy(() => import('./PoolFinder'));
// const RemoveLiquidity = lazy(() => import('./RemoveLiquidity'));
// const Info = lazy(() => import('./Info'));
// const Swap = lazy(() => import('./Swap'));
// const StationPool = lazy(() => import('./StationPool'));
// const StationPoolFinder = lazy(() => import('./StationPoolFinder'));
// const StationRemoveLiquidity = lazy(() => import('./StationRemoveLiquidity'));
// const Bridge = lazy(() => import('./Bridge'));
// const Stake = lazy(() => import('./Stake'));
// const Test = lazy(() => import('./Test'));
// const StationAddLiquidity = lazy(() => import('./StationAddLiquidity'));
// const Migrate = lazy(() => import('./Migrate'));
// const Liquidity = lazy(() => import('./Liquidity'));
const ResponseiveFlex = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};  
  @media (max-width: 1200px) {
    flex-direction: column!important;
    flex-flow: wrap-reverse!important;
  }
`
const ResponsiveBox = styled.div`
  @media (max-width: 1200px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const ResponsiveSection = styled.section<{ maxWidth: string }>`
  width: 100%;
  max-width: ${(({ maxWidth }) => (maxWidth || '464px'))};
  margin-bottom: 20px;
  position: relative;
`
const ResponsiveLeftContainer = styled.section`
max-width: 600px;
width: 100%;
margin-left: auto;
margin-right: auto;
`

const ResponsiveLeftContainerForStation = styled.section`
max-width: 710px;
width: 100%;
margin-left: auto;
margin-right: auto;
`

const Divider = styled.div`
display: inline-block;
width: 177px;
height: 100%;
@media(max-width: 1200px) {
  display: none;
}
`

const AppWrapper = styled.div`
display: flex;
flex-flow: column;
align-items: flex-start;
overflow-x: hidden;
min-height:100vh;
justify-content:space-bettween;
`
const WrapperPadding = styled.div`
width: 100%;
max-width: 1220px;
margin-left: auto;
margin-right: auto;
// background:url(${hurricane}) no-repeat top center;
background:${(() => { return (window.location.hash === '#/migrate') ? `url(${hurricane}) no-repeat  center 0px` : 'none' })};
`
const BackgroundCover = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all linear .2s;
    background: ${({ theme }) => (theme.colors.gradients.backgroundCover)};
    z-index:-2;
`

const BodyWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
padding: 80px 16px;
padding-bottom: 0;
align-items: center;
flex: 1;
overflow-y: auto;
overflow-x: hidden;
z-index: 1;
justify-content: space-between;
    
    ${({ theme }) => theme.mediaQueries.xs} {
  background-size: auto;
}
    
    ${({ theme }) => theme.mediaQueries.lg} {
  min-height: 90vh;
}
`

const Marginer = styled.div`
margin-top: 5rem;
`

const Fixed = styled.section`
position: fixed;
top: 100px;
right: 100px;
`
const TabNavItem = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  text-align: center;
  color: #ACA4B1;
  border-bottom:4px solid #CFCAD3;
  padding:10px 20px;
`
const TabNavItemActive = styled(TabNavItem)`
  color: #4D3C55;
  border-bottom:4px solid #6425C5;
`

const TradingView = () => {
  const ifMobile = useMobile(1201)
  const { pathname } = useLocation()
  if (pathname.includes('home') || pathname === '/' || pathname.includes('bridge') || pathname.includes('info')) {
    return null
  }
  if (pathname.includes('home') || pathname === '/' || pathname.includes('stake')) {
    return (
      <Flex mr={ifMobile ? '0' : '30px'} mt={ifMobile ? '50px' : '0'} maxWidth='710px' width='100%' mb='20px'>
        <ResponsiveLeftContainer >
          <Box >
            {/* @ts-ignore */}
            <StakeDec />
          </Box>
        </ResponsiveLeftContainer>
      </Flex>
    )
  }

  // if (pathname.includes('station')) {
  //   return (
  //     <Flex mr={ifMobile ? '0' : '30px'} mt={ifMobile ? '50px' : '0'} width='100%' mb='20px'>
  //       <ResponsiveLeftContainerForStation >
  //         <Box >
  //           <StationTokenInfo />
  //         </Box>
  //       </ResponsiveLeftContainerForStation>

  //     </Flex>
  //   )
  // }

  if (pathname.includes('migrate')) {
    return (
      <Flex mr={ifMobile ? '0' : '30px'} mt={ifMobile ? '50px' : '0'} width='100%' mb='20px'>
        <ResponsiveLeftContainer >
          <Box >
            <MigrateDes />
          </Box>
        </ResponsiveLeftContainer>

      </Flex>
    )
  }
  return (
    <Flex mr={ifMobile ? '0' : '30px'} mt={ifMobile ? '50px' : '0'} maxWidth='710px' width='100%' mb='20px'>
      <ResponsiveLeftContainer >
        <Box >
          <TokenInfo />
        </Box>
      </ResponsiveLeftContainer>
    </Flex>
  )
}

const ResponsiveRouterView = () => {
  const { pathname } = useLocation()

  return (
    <ResponsiveSection id="dex" maxWidth={pathname.includes('info') ? '100%' : '464px'}>
      <Column style={{ width: '100%' }}>
        <Info />
      </Column>
      {/* <ToastContainer toasts={toasts} onRemove={onRemove}/> */}
      {/* <Route exact strict path="/bridge" component={Bridge} /> */}
      <Route exact strict path="/stake" component={Stake} />
      <Route exact strict path="/unstake" component={Stake} />
      <Route exact strict path="/swap" component={Swap} />
      <Route exact strict path="/migrate" component={Migrate} />
      <Route exact strict path="/liquidity" component={Liquidity} />
      <Route exact strict path="/find" component={LPFinder} />
      {/* <Route exact strict path="/stationfind" component={StationPoolFinder} /> */}
      <Route exact strict path="/pool" component={Pool} />
      {/* <Route exact strict path="/stationPool" component={StationPool} /> */}
      <Route exact strict path="/test" component={Test} />
      <Route exact strict path="/add" component={AddLiquidity} />
      {/* <Route exact strict path="/stationadd" component={StationAddLiquidity} /> */}
      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
      {/* <Route exact strict path="/stationremove/:currencyIdA/:currencyIdB" component={StationRemoveLiquidity} /> */}
      {/* <Route path="/info/overview" exact strict component={Overview} />
    <Route path="/info/pairs" exact strict component={Pools} />
    <Route path="/info/tokens" exact strict component={Tokens} /> */}

      {/* Redirection: These old routes are still used in the code base */}
      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
      {/* <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} /> */}

      {/* <Route exact path="/stationadd/:chainId/:currencyIdA" component={RedirectStationOldAddLiquidityPathStructure} />
      <Route exact path="/stationadd/:chainId/:currencyIdA/:currencyIdB" component={RedirectStationDuplicateTokenIds} />
      <Route exact strict path="/stationremove/:tokens" component={RedirectStationOldRemoveLiquidityPathStructure} /> */}

      <Route exact path="/swap/:chainId/:currencyIdA" component={RedirectSwapDuplicateTokenIds} />
      <Route exact path="/swap/:chainId/:currencyIdA/:currencyIdB" component={RedirectSwapDuplicateTokenIds} />
      {/* <Route component={RedirectPathToSwapOnly} /> */}
      {/* <Redirect to="/home"/> */}
    </ResponsiveSection>


  )
}

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const [migrateBg, setMigrateBg] = useState<any>('')
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY} `
  const [isMigrate, setIsMigrate] = useState(false)

  const credentials: Credentials = {
    token: apiKey,
  }
  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  // 开启轮询
  // const {stationStatus, error: contractError} = useStationStatus()

  const { account } = useActiveWeb3React()
  const { login } = useAuth()
  // 自动登录
  useEffect(() => {
    if (!account) {
      login()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <BackgroundCover />

          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu />
              <Popups />
              <BodyWrapper>

                <Web3ReactManager>
                  <>
                    <Switch>
                      <WrapperPadding >
                        <ResponseiveFlex justifyContent="space-around">
                          <TradingView />
                          <Route exact strict path="/" component={Home} />
                          <Route exact strict path="/home" component={Home} />

                          <ResponsiveRouterView />
                        </ResponseiveFlex>
                      </WrapperPadding>

                    </Switch>
                    <Footer />
                  </>
                </Web3ReactManager>
              </BodyWrapper>
              {/* <VersionBar /> */}
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense >
  )
}
