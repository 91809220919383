import React, { useContext, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, CardBody, Text } from '@hurricaneswap/uikit'
import { Link, useLocation } from 'react-router-dom'
import { TYPE } from 'components/Shared'
import { AutoColumn } from 'components/Column'
import PageHeader from 'components/PageHeader'
import AppBody from '../AppBody'


const { body: Body } = TYPE

export default function Liquidity() {
  const { t } = useTranslation()
  return (
    <>
      <AppBody>
        <PageHeader title="Liquidity" showSettings={false} />
        <AutoColumn gap="lg" justify="center">
          <CardBody p='24px 24px 48px' style={{ width: '100%' }}>
            <AutoColumn gap="12px" style={{ width: '100%' }}>

              <div>
                <Text fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
                  {/* eslint-disable-next-line */}
                  {t("Migrate your liquidity from Hurricane-V1 to V2.")}
                </Text>

                <Button as={Link} to="/migrate" style={{ width: '100%', marginTop: "20px" }} >
                  {t('Migrate')}
                </Button>
              </div>
            </AutoColumn>
          </CardBody>
        </AutoColumn>
      </AppBody>
    </>
  )
}
