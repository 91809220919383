import { useEffect, useState } from 'react'

type ApiResponse = {
  data: {
    [address: string]: {
      name: string
      symbol: string
      price:string
      price_HT:string
    }
  }
  updated_at: string
}

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */

// pantherswap
const api = 'https://api.pantherswap.info/api/tokens'
// mdex
// const api = 'https://ht.mdex.com/tokenlist.json?t=1621865558612'

const useGetPriceData = () => {
  const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(api)
        const res: ApiResponse = await response.json()
        setData(res)
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    // fetchData()
  }, [setData])

  return data
}

export default useGetPriceData
