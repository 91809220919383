import React, { useMemo } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@hurricaneswap/uikit'
import TranslatedText from '../TranslatedText'

const StyledNav = styled.div`
  z-index: 5;
  // height: 100%;
  display: flex;
  align-items: center;
`

const StyledHeader = styled.div`
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 1.5rem 2.5rem;
`

const StyledNavLink = styled(NavLink)`

color: ${({ theme }) => (theme.colors.text)}
`

const Nav = ({ activeIndex = 0 }: { activeIndex?: number }) => {
  const { search, pathname } = useLocation()

  const Links = useMemo(() => {
    if (pathname.includes('station')) {
      return (
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/stationPool", search }}>Station</StyledNavLink>
      )
    }
    if (pathname.includes('bridge')) {
      return (
        <StyledNavLink style={{ marginRight: '23px' }} to={{ pathname: "/bridge", search }}>HCT-bridge</StyledNavLink>
      )
    }
    if (pathname.includes('stake') || pathname.includes('unstake')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/stake", search }}>Stake</StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/unstake", search }}>Unstake</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('migrate')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/migrate", search }}>Your Hurricane-V1 Liquidity</StyledNavLink>
        </>
      )
    }
    if (pathname.includes('add') || pathname.includes('liquidity') || pathname.includes('addLiquidity') || pathname.includes('myLiquidity')) {
      return (
        <>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/swap", search }}>Swap</StyledNavLink>
          <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/liquidity", search }}>My Liquidity</StyledNavLink>
        </>
      )
    }
    return (
      <>
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/swap", search }}>Swap</StyledNavLink>
        <StyledNavLink activeStyle={{ opacity: 1 }} style={{ marginRight: '23px', opacity: 0.45 }} to={{ pathname: "/liquidity", search }}>Liquidity</StyledNavLink>
      </>
    )
  }, [pathname, search])

  return (
    (
      <StyledNav>
        {Links}
      </StyledNav>
    )
  )
}

export default Nav
