import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { JSBI, Pair, Percent } from '@glhf-libs/sdk'
import useRequest from '@ahooksjs/use-request'
import { Button, Card as UIKitCard, CardBody, Text } from '@hurricaneswap/uikit'
import BigNumber from 'bignumber.js'

import { darken } from 'polished'
import { maxAllowance } from 'constants/index'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Loader from 'components/Loader'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useMigrateContract, useTokenContract } from 'hooks/useContract'
import { useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { calculateSlippageAmount } from 'utils'
import { NormalStyleInput as NumericalInput } from '../NumericalInput'

import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import Card from '../Card'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed } from '../Row'
import { AppState } from '../../state'
import { useBaseCoin } from '../../hooks/useBaseCoin'
import useOnlyOnAvax from '../../hooks/useOnlyOnAvax'
import useTheme from '../../hooks/useTheme'



export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
  cursor: pointer;
`
export const VersionWrap = styled(RowBetween)`
  height: 24px;
  border:1px solid ${({ theme }) => (theme.colors.secondary)};
  color:${({ theme }) => (theme.colors.secondary)};
  padding:0 12px;
  font-size:12px;
`
export const HoverCard = styled(Card)`
  border: 1px solid ${({ theme }) => { return theme.isDark ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.12)' }};
  background:rgba(76, 35, 118, 0.04);
  :hover {
    background:  ${({ theme }) => { return theme.isDark ? '#2a1f34' : 'rgba(255, 255, 255, 0.6)' }};
  }
`

const ShowCard = styled(Card)`
  background:${({ theme }) => theme.colors.invertedContrast};
  border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.colors.invertedContrast)};
    background: rgba(255, 255, 255, 0.6);
  }
`
const MigrateOperateCard = styled(Card)`
margin-top:24px;
padding:0.75rem 1rem;
background: rgba(141, 128, 213, 0.1);
`
const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`

interface MigrateCardProps {
  pair: Pair
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean
  // eslint-disable-next-line react/no-unused-prop-types
  loading?: any
  resultHandler?: any
}
function MigrateCard({ pair, resultHandler }: MigrateCardProps) {
  const { theme } = useTheme()
  // const migrateContractAddress = '0xc7b2E023FD555bb343F0C8eCd1F6e7660D2B25FE'
  const IFMAINNET = process.env.REACT_APP_ENV === 'MAIN'

  const migrateContractAddress = IFMAINNET ? '0x842BcaABcdcDA2281ea84Fe02cb0F9FEC1bDaF7C' : '0x62C6Eb32618ED28afE73B72c8F1fc3B552C557E4'
  // const newRouterAddress = '0x9993A4fF08d3D179475dB58c1DC9Cd1D05678982'
  const decimals = pair.liquidityToken.decimals
  const { account, chainId } = useActiveWeb3React()
  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const currency0symbol = useBaseCoin(currency0, chainId)
  const currency1symbol = useBaseCoin(currency1, chainId)
  const [showMore, setShowMore] = useState(false)
  const [migrateAmount, setMigrateAmount] = useState('')// 用户输入的数字 :string
  const userLPTokenBalance = useTokenBalance(account ?? undefined, pair.liquidityToken) // 用户LP TOKEN数量 ：TokenAmount
  const [lpTokenBalance, setLpTokenBalance] = useState('')// 用户LP TOKEN数量 
  const [balcance, setBalance] = useState('')
  const [migrateBtnDisable, setMigrateBtnDisable] = useState(true)
  const [migrateBtnText, setMigrateBtnText] = useState('Enter an amount')
  const [migrateErr, setMigratemigrateErr] = useState(false)
  const [approveBtnDisable, setApproveBtnDisable] = useState(true)
  const [approveBtnText, setApproveBtnText] = useState('Approve')
  const [approving, setApproving] = useState(false)
  const [migrating, setMigrating] = useState(false)
  // const newRouterContract = useMigrateContract(newRouterAddress, true)
  const migrateContract = useMigrateContract(migrateContractAddress, true)
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()
  const tokenContract = useTokenContract(pair.liquidityToken.address || undefined)
  const addTransaction = useTransactionAdder()
  // console.log('pair', pair)
  const [allowBalance, setAllowBalance] = useState('')// 授权的额度 包含decimals
  const { run: getAllowance, data: allowanceData, error: allowanceError, loading: allowanceLoading }
    = useRequest(() => tokenContract?.allowance(account, migrateContractAddress), { manual: true })

  const { run: approve, data: approveData, error: approveEror, loading: approveLoading }
    = useRequest(() => tokenContract?.approve(migrateContractAddress, maxAllowance), { manual: true, throwOnError: true })
  useEffect(() => {
    getAllowance().then(res => {
      // console.log('获取已经授权的额度allowBalance：', res.toString())
      const allowBalanceStr = res?.toString()
      // 获取approve额度  不包含小数的字符串
      setAllowBalance(allowBalanceStr)
    })
  }, [pair, getAllowance])

  const onUserInput = (input) => {
    const checkStr = input.split('.')
    if (checkStr[1]?.length > decimals) {
      input = `${checkStr[0]}.${checkStr[1].slice(0, decimals)}`
    }
    // console.log('用户输入的数量：', input)
    setMigrateAmount(input)
  }

  useEffect(() => {
    setLpTokenBalance(userLPTokenBalance.raw.toString())
    setBalance(userLPTokenBalance.toExact())
  }, [userLPTokenBalance])

  const setMaxMigrateAmount = useCallback(() => {
    setMigrateAmount(userLPTokenBalance.toExact())
  }, [userLPTokenBalance])

  const actionApprove = useCallback(() => {
    const tokenASymbol = pair.token0.symbol
    const tokenBSymbol = pair.token1.symbol
    if (approveBtnDisable) {
      return
    }
    console.log('approve', lpTokenBalance, allowBalance)
    if (new BigNumber(lpTokenBalance).gt(allowBalance)) {
      setApproving(true)
      // approve  数量
      approve().then(approveResult => {
        // console.log('approve res', approveResult)
        addTransaction(approveResult, {
          summary: `Approve ${tokenASymbol}/${tokenBSymbol} ${userLPTokenBalance.toExact()}`,
        })
        approveResult.wait().then(res => {
          setApproving(false)
          setAllowBalance(lpTokenBalance)
          resultHandler({
            attemptingTxn: false,
            pendingText: `${tokenASymbol}/${tokenBSymbol} Approve success. `,
            txHash: res?.transactionHash,
            status: res?.status,
            popupSummary: `${tokenASymbol}/${tokenBSymbol} Approve success. `
          })

        }, err => {
          resultHandler({
            attemptingTxn: false,
            pendingText: `${tokenASymbol}/${tokenBSymbol} Approve Failed. `,
            txHash: undefined,
            status: 0,
            popupSummary: `${tokenASymbol}/${tokenBSymbol} Approve Failed. `
          })
        })
      }).catch((e) => {
        // console.log('approve e', e)
        setApproving(false)
        setApproveBtnDisable(false)
        setApproveBtnText('Approve')
        if (e.code === 4001) {
          resultHandler({
            attemptingTxn: true,
            pendingText: '',
            txHash: '',
            action: 'approve',
            hidePopup: true
          })
        } else {
          resultHandler({
            migrateErrorMessage: e?.data?.message
          })
        }
      })
    }

  }, [approveBtnDisable, allowBalance, lpTokenBalance, approve, pair, resultHandler, addTransaction, userLPTokenBalance])

  const actionMigrate = useCallback(async () => {
    if (migrateBtnDisable || Number(migrateAmount) <= 0) {
      return
    }
    const pairAddress = pair.liquidityToken.address
    const tokenA = pair.token0.address
    const tokenB = pair.token1.address
    const tokenASymbol = pair.token0.symbol
    const tokenBSymbol = pair.token1.symbol
    const lpAmountBigNumber = new BigNumber(migrateAmount);
    const lpAmount = lpAmountBigNumber.times(10 ** decimals).toFixed()
    const tokenAMin = 0
    const tokenBMin = 0
    const recipient = account
    const ddl = Math.ceil(Date.now() / 1000) + deadline

    const allowanceResult = await getAllowance()
    // 如果允许的额度小于LP TOKEN的数量，则重新approve
    if (new BigNumber(userLPTokenBalance.raw.toString()).gt(allowanceResult.toString())) {
      setAllowBalance(allowanceResult.toString())
      setApproving(true)
      approve()
      return
    }
    // console.log({ lpToken: pairAddress, tokenA, tokenB, liquidity: lpAmount, migrateAmount, amountAMin: tokenAMin, amountBMin: tokenBMin, to: recipient, deadline: ddl })
    setMigrateBtnText('Migrating')
    setMigrateBtnDisable(true)
    setMigrating(true)
    // migration(pair地址，tokenA，tokenB，流动性数量，最少A，最少B，接收人，ddl）
    await migrateContract?.migration(pairAddress, tokenA, tokenB, lpAmount, tokenAMin, tokenBMin, recipient, ddl).then(migrationResult => {
      const newLPtokenBalcance = new BigNumber(lpTokenBalance).minus(lpAmount)
      setLpTokenBalance(newLPtokenBalcance.toString())
      resultHandler({
        attemptingTxn: true,
        pendingText: `${tokenASymbol}/${tokenBSymbol} Migrating ${migrateAmount} LP token`,
        txHash: migrationResult?.hash,
        showPopup: true
      })
      addTransaction(migrationResult, {
        summary: `Migrate ${tokenASymbol}/${tokenBSymbol} ${migrateAmount}`,
      })
      migrationResult.wait().then(res => {
        // console.log('migrationResult wait', res)
        // setMigrateBtnText('Migrate')
        // setMigrateBtnDisable(false)
        setMigrating(false)
        setMigrateAmount('')

        resultHandler({
          attemptingTxn: false,
          pendingText: 'Migrating LP Token',
          txHash: res?.transactionHash,
          status: res?.status,
          popupSummary: `${tokenASymbol}/${tokenBSymbol} Migrating ${migrateAmount} ${res.status ? 'success' : 'failed'}`
        })
      }, err => {
        setMigrateBtnText('Migrate')
        setMigrateBtnDisable(false)
        setMigrating(false)
        setMigrateAmount('')

        resultHandler({
          attemptingTxn: false,
          pendingText: 'Migrating LP Token Failed',
          txHash: undefined,
          status: 0,
          popupSummary: `${tokenASymbol}/${tokenBSymbol} Migrating ${migrateAmount} failed`
        })
      })
    }).catch((e) => {
      setMigrateBtnText('Migrate')
      setMigrateBtnDisable(false)
      setMigrating(false)
      if (e.code === 4001) {
        resultHandler({
          attemptingTxn: true,
          pendingText: '',
          txHash: '',
          action: 'migrate',
          hidePopup: true
        })
      } else {
        console.log("00000000000", e?.data?.message)
        // TODO  错误提示
        resultHandler({
          migrateErrorMessage: e?.data?.message
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, migrateBtnText, migrateBtnDisable, pair, userLPTokenBalance, migrateContract, deadline, resultHandler])


  useEffect(() => {
    // console.log('lpTokenBalance=', lpTokenBalance, ' allowBalance=', allowBalance, ' Number(allowBalance)=', Number(allowBalance))

    if (approving) {
      setApproveBtnDisable(true)
      setApproveBtnText('Approving')
    } else if (new BigNumber(lpTokenBalance).gt(0) && (Number(allowBalance) === 0 || new BigNumber(lpTokenBalance).gt(allowBalance))) {
      setApproveBtnText('Approve')
      setApproveBtnDisable(false)
    } else {
      setApproveBtnText('Approved')
      setApproveBtnDisable(true)
    }
  }, [approving, allowBalance, lpTokenBalance])


  useEffect(() => {
    // console.log("new BigNumber(allowBalance).gte(lpTokenBalance)", new BigNumber(allowBalance).gte(lpTokenBalance), allowBalance, lpTokenBalance)
    if (new BigNumber(allowBalance).gte(lpTokenBalance)) {
      const migrateAmountDecimals = new BigNumber(migrateAmount).times(10 ** decimals)
      // console.log('监听用户输入的数量：', migrateAmount, migrateAmountDecimals, lpTokenBalance)
      // console.log('监听用户输入的数量 gt：', migrateAmountDecimals.gt(lpTokenBalance)) 
      if (migrating) {
        setMigrateBtnDisable(true)
        setMigratemigrateErr(false)
      } else if (Number(migrateAmount) <= 0) {
        setMigrateBtnText('Enter an amount')
        setMigrateBtnDisable(true)
        setMigratemigrateErr(false)
      } else if (migrateAmountDecimals.gt(lpTokenBalance)) {
        setMigrateBtnText('Insufficient Balance')
        setMigrateBtnDisable(true)
        setMigratemigrateErr(true)
      } else {
        setMigrateBtnText('Migrate')
        setMigrateBtnDisable(false)
      }
    } else {
      setMigrateBtnDisable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [migrateAmount, lpTokenBalance, allowBalance])



  return (
    <HoverCard style={showMore ? (theme.isDark ? { background: '#2a1f34' } : { background: '#fff' }) : {}}>
      <AutoColumn gap="12px">
        <FixedHeightRow onClick={() => { setShowMore(!showMore); setMigrateAmount('') }}  >
          <RowFixed>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin size={20} />
            <Text>{!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0symbol}/${currency1symbol}`}</Text>
          </RowFixed>
          <RowFixed>
            <VersionWrap> Hurricane-V1 </VersionWrap>
            {showMore ? (
              <ChevronUp size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FixedHeightRow>
        {showMore && (
          <AutoColumn gap="20px">
            <MigrateOperateCard style={migrateErr ? { border: '1px solid #FF3B3B', boxShadow: 'rgb(239 8 8) 0px 0px 3px 0px' } : {}}>
              <Text>Amount of LP token</Text>
              <RowBetween >
                <NumericalInput
                  value={migrateAmount}
                  disabled={migrating}
                  onUserInput={val => {
                    onUserInput(val)
                  }}
                />
                <Button scale="sm" disabled={migrating} style={{ marginLeft: '1rem' }} onClick={() => setMaxMigrateAmount()}>  Max </Button>
              </RowBetween>
              <Text>
                Balance: {balcance}
              </Text>
            </MigrateOperateCard>


            {(new BigNumber(allowBalance).eq(0)) &&
              <Button
                width='100%'
                disabled={approveBtnDisable}
                onClick={actionApprove}>
                {approving ? (<Dots>Approving</Dots>) : approveBtnText}
              </Button>}
            <Button
              width='100%'
              disabled={migrateBtnDisable}
              onClick={actionMigrate}>
              {migrating ? (<Dots>Migrating</Dots>) : migrateBtnText}
            </Button>
          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard >
  )
}
export default MigrateCard
